@import '~js-datepicker/dist/datepicker.min.css';


/**********************************/
/**********************************/
/*  0. MAIN  */


:root {
  --section-rotate: 9vw;
}

html {
  font-size: 62.5%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  --primaire: #fff;
  --secondaire: #555;
  --overlay: #f2f2f2;
  --placeholder: #999;
  --tertiaire: #5c74ff;
  --quaternaire: #c5ec46;
  --quinquenaire: #f78425;
  line-height: 1.6;
  font-weight: 400;
  font-family: 'Quicksand', sans-serif;
  /* color: #000; */
  background-color: var(--primaire);
  color: var(--secondaire);
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding:
    env(safe-area-inset-top, 10px) env(safe-area-inset-right, 10px) env(safe-area-inset-bottom, 10px) env(safe-area-inset-left, 10px);
}

/* body::-webkit-scrollbar {
  display: none;
} */

body.dark {
  --primaire: #444;
  --secondaire: #eee;
  --overlay: #666;
  --tertiaire: #f78425;
  --quinquenaire: #5c74ff;
}

@media (prefers-color-scheme: dark) {
  body {
    --primaire: #444;
    --secondaire: #eee;
    --overlay: #666;
    --tertiaire: #f78425;
    --quinquenaire: #5c74ff;
  }

  body.light {
    --primaire: #fff;
    --secondaire: #555;
    --overlay: #f2f2f2;
    --tertiaire: #5c74ff;
    --quinquenaire: #f78425;
  }
}

::-moz-selection {
  background-color: var(--tertiaire);
  color: var(--primaire);
}

::selection {
  background-color: var(--tertiaire);
  color: var(--primaire);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

#splash {
  display: none;
  background-color: #444;
  background-image: url('../img/splash-screen.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  transition-property: opacity;
  transition-duration: 300ms;
  transition-delay: 2000ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

#splash.animate {
  opacity: 0;
}

#pwaInstall {
  display: none;
  width: 100%;
  height: 20vh;
  background-color: #444;
  padding: 1rem 2rem;
}

#pwaInstall p {
  color: #fff;
}

#pwaInstall svg {
  height: 2rem;
  width: 2rem;
  fill: #fff;
  margin-right: 1rem;
}

#pwaInstall button {
  position: absolute;
  right: 3rem;
  bottom: 4rem;
}

#spinner {
  position: fixed;
  z-index: 9000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: #444; */
  background-color: #fff;
}

#spinner .spin {
  top: calc(50% - 0.5em);
  left: calc(50% - 0.5em);
  color: #444;
  font-size: 12px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}

#btn-spin {
  color: #fff;
  font-size: 4px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 0.5em);
  margin-left: 2em;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}

@keyframes mulShdSpin {

  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em,
      2em -2em 0 0em, 3em 0 0 -1em,
      2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 0;
  }

  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em,
      3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  25% {
    box-shadow: 0 -3em 0 -0.5em,
      2em -2em 0 0, 3em 0 0 0.2em,
      2em 2em 0 0, 0 3em 0 -1em,
      -2em 2em 0 -1em, -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }

  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
      3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em,
      -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
      3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em,
      -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }

  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
      3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0,
      -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }

  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em,
      3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }

  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em,
      3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em,
      -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

main {
  /* background-color: #f7f7f7; */
  position: relative;
  display: flex;
  margin: 0 auto;
  /* width: 150rem;
  max-width: 90%; */
  background-color: var(--primaire);
  /* -webkit-box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.06);
  box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.06); */
  padding: 5rem 0rem;
  max-width: 90%;
  width: 1200px;
  border-radius: 5px;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: calc(100vh - 25rem);
  /* min-height: calc(var(--vh, 1vh) * 100 - 22rem); */
}

section {
  padding-top: 5rem;
}

main.map {
  width: 100%;
  max-width: 100%;
  padding: 0;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  height: calc(100vh - 25rem);
  overflow: scroll;
}

main.help,
main.scan {
  width: 800px;
}

main.help img {
  width: 600px;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  -webkit-filter: drop-shadow(5px 5px 5px #aaa);
  filter: drop-shadow(5px 5px 5px #aaa);
}

h1 {
  margin-bottom: 3rem;
  text-align: center;
}

h1,
h1>span,
h1>strong {
  font-family: 'Roboto Slab', serif;
  color: var(--secondaire);
  font-size: 4rem;
}

h2 {
  font-family: 'Roboto Slab', serif;
  color: var(--secondaire);
  font-size: 2.5rem;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

h3:not(.heading-secondary) {
  margin-top: 2rem;
  color: var(--secondaire);
  font-size: 1.8rem;
}

h4 {
  margin-top: 1rem;
  color: var(--secondaire);
  font-size: 1.6rem;
  font-weight: 2rem;
}

h5 {
  font-size: 1.5rem;
  color: var(--secondaire);
}

h6 {
  font-size: 1rem;
  color: var(--secondaire);
}

p,
ul {
  font-size: 1.5rem;
  margin: 1rem 0 1rem 0;
  list-style: none;
}

h1 strong {
  color: var(--quaternaire) !important;
}

h1.h1-small {
  font-size: 2.5rem;
  text-align: left;
}

h2.h2-small {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

/* select,
option,
input[type=text] {
  font-size: 1.5rem;
  font-family: inherit;
  padding: 0.3rem;
  border: 1px solid var(--secondaire);
  border-radius: 0;
  background-color: var(--primaire);
  color: var(--secondaire);
} */

#cgContent ul {
  list-style: disc;
  list-style-position: inside;
}

#cgContent ul.level2 {
  list-style: circle;
  list-style-position: inside;
}

#cgContent li {
  padding-left: 2rem;
}

#dashboard-user {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
}

span,
strong,
em,
i,
a {
  font-size: inherit;
  /* font-size: 1.5rem; */
}

/* a:focus:not(:focus-visible),
a:focus:-moz-focusring {
  outline-style: none;
  background: transparent;
} */

strong {
  color: var(--secondaire);
  font-weight: 600;
}

.quote {
  font-family: 'Josefin Sans', sans-serif;
}

.beware {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--quinquenaire);
  margin-bottom: 2rem;
}

/**********************************/
/**********************************/
/*  1. FLEX classes  */


.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.start {
  justify-content: flex-start !important;
}

/**********************************/
/**********************************/
/*  1. HOME  */

#main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

#main h1 {
  text-align: left;
}

#orizone,
#quoi,
#qui,
#comment {
  padding-top: 8vw;
}

#avantages {
  padding-top: 5vw;
}

#main-text {
  width: 60%;
}

#main-text li {
  justify-content: left;
  align-items: center;
}

#main-text li:not(:first-child) {
  margin-top: 2rem;
}

#main-text a {
  margin-left: 0px;
}

#main-text svg {
  width: 5rem;
  height: 5rem;
}

#main-text>ul>li:nth-child(odd) svg {
  transform: scale(-1, 1);
}

#main-text h5 {
  width: 360px;
  margin-left: 2rem;
}

#main-vid {
  width: 40%;
  position: relative;
  height: 40rem;
}

/* #video {
  display: none;
  position: absolute;
  top: 0;
  margin: 0 auto;
  width: 100%;
} */

#video-container {
  margin-top: 12rem;
  width: 50%;
  padding-bottom: 25.6%;
  position: relative;
}

#video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#img1,
#img2,
#img3 {
  opacity: 0.8;
  border-radius: 10px;
  position: absolute;
  transition: top 0.5s ease-in-out, right 0.5s ease-in-out;
}

#img1 {
  top: 0rem;
  right: 0rem;
  transition-duration: 0.2s;
  width: 75%;
}

#img2 {
  top: 20rem;
  right: 38rem;
  transition-delay: 0.4s;
  transition-duration: 0.2s;
  width: 50%;
}

#img3 {
  top: 24rem;
  right: 7rem;
  transition-delay: 0.2s;
  transition-duration: 0.2s;
  width: 60%;
}

#img1.rotate {
  top: 24rem;
  right: 0rem;
  transition-delay: 0.4s;
  transition-duration: 0.2s;
}

#img2.rotate {
  top: 9rem;
  right: 38rem;
  transition-delay: 0s;
  transition-duration: 0.2s;
}

#img3.rotate {
  top: 24rem;
  right: 38rem;
  transition-delay: 0.2s;
  transition-duration: 0.2s;
}

#qui .card {
  height: 45rem;
  justify-content: space-between;
}


.play-sign {
  position: absolute;
  top: 18rem;
  left: 6rem;
  height: 100px;
  width: 100px;
  background-color: var(--quinquenaire);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
  animation: 4s infinite alternate breathe;
}

@keyframes breathe {
  20% {
    transform: scale(1)
  }

  50% {
    transform: scale(1.2)
  }

  80% {
    transform: scale(1)
  }
}

.play-sign:hover {
  background-color: var(--quaternaire);
}

.triangle {
  pointer-events: none;
  position: relative;
  top: 25px;
  left: 33px;
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 43px solid white;
  /* animation: 4s infinite alternate beat; */
}

#quoi :nth-child(2) img {
  margin-left: 10%;
}

#quoi :nth-child(3) img {
  margin-right: 10%;
}

#avantages :nth-child(odd) img {
  margin-left: 10%;
}

#avantages :nth-child(even) img {
  margin-right: 10%;
}

main#packets {
  width: 95%;
  max-width: 95%;
}

.w10 {
  width: 10%;
}

.w15 {
  width: 15%;
}

.w20 {
  width: 20%;
}

.w23 {
  width: 23%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w33 {
  width: 32%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w55 {
  width: 55%;
}

.w60 {
  width: 60%;
}

.w66 {
  width: 66%;
}

.w70 {
  width: 70%;
}

.w75 {
  width: 75%;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

.ma-bt-md {
  margin-bottom: 1.5rem !important;
}

.ma-bt-lg {
  margin-bottom: 2rem !important;
}

.right {
  text-align: right !important;
}

.line {
  margin: 6rem 0;
  width: 100%;
  height: 1px;
  background-color: var(--primaire);
}

a {
  /* color: #000; */
  color: var(--secondaire);
  text-decoration: none;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

a.link {
  color: var(--tertiaire);
  text-align: right;
  text-decoration: underline;
  font-size: 1.5rem;
}

a.link:hover {
  color: var(--quinquenaire);
}

.chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: '';
  display: inline-block;
  height: 0.45em;
  left: -0.3em;
  position: relative;
  vertical-align: top;
  width: 0.45em;
}

.chevron.right::before {
  transform: rotate(45deg);
  top: 0.6em;
}

.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}

.filters {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column-reverse;
  background-color: var(--primaire);
  padding: 1rem;
}

.filters .row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0.7rem;
}

.filters .col {
  display: flex;
  flex-direction: column;
  margin: 0.7rem;
}

.overview-box__detail.details {
  display: none;
}

.displayDetails:checked~.details {
  display: flex;
}

.displayDetails:checked~label .chevron.right::before {
  transform: rotate(135deg);
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  /* display: inline-block; */
  margin: 0 0.5rem 0 0.5rem;
}

.dropbtn {
  /* background-color: #4CAF50;
  color: white; */
  padding: 16px;
  /* font-size: 16px; */
  border: none;
  cursor: pointer;
  width: 100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  width: 100%;
  background-color: var(--primaire);
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 300;
}

/* Links inside the dropdown */
.dropdown-content a {
  /* color: black; */
  padding: 0.7rem 0.7rem;
  text-decoration: none;
  display: block;
  position: relative;
  width: 100%;
}

.dropdown-content a:hover {
  background-color: var(--secondaire);
  color: var(--primaire);
}

.dropbtn::after {
  width: 0;
  height: 0;
  border: 0.313em solid transparent;
  border-bottom: none;
  border-top-color: var(--secondaire);
  content: '';
  vertical-align: middle;
  display: inline-block;
  position: relative;
  right: -0.313em;
}

/* Change color of dropdown links on hover */
/* .dropdown-content a:hover {background-color: #f1f1f1} */

/* Show the dropdown menu on hover */
/* .dropdown:hover .dropdown-content {
  display: block;
} */


/* A TESTER POUR UN HOVER QUI FONCTIONNE CORRECTEMENT SUR MOBILE */
/* @media(hover: hover) and (pointer: fine) {
  .dropdown:hover .dropdown-content {
    display: block;
  }
} */

/* Change the background color of the dropdown button when the dropdown content is shown */
/* .dropdown:hover .dropbtn {
  background-color: var(--quaternaire);
} */

.pagination {
  margin: 0 auto;
}

.pagination span {
  margin: 0 3px 0 3px;
}

.pagination a {
  padding: 5px;
  border: 1px solid rgb(68, 68, 68);
  margin: 2px;
}

.pagination a.inactive {
  pointer-events: none;
  color: #bbb;
}

.pagination a.inactive {
  pointer-events: none;
}

.pagination a.active:hover {
  background-color: var(--quaternaire);
}

/* a:hover,
a:active {
  color: var(--tertiaire);
} */

/**********************************/
/**********************************/
/*  1. ALERTS & MODALS  */

.alert {
  position: fixed;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 9999;
  color: var(--secondaire);
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1.6rem 15rem;
  -webkit-box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.25);
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.25);
}

.alert--success {
  background-color: var(--quaternaire) !important;
}

.alert--error {
  background-color: var(--quinquenaire) !important;
}

.alert--error strong,
.alert--error span {
  color: #fff !important;
}

.modal--overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8888;
  background-color: var(--primaire);
  color: var(--secondaire);
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
  /* border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; */
  /* padding: 1.6rem 15rem;
  -webkit-box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.25);
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.25); */
}

.modal {
  position: fixed;
  width: 90%;
  height: 50%;
  top: 25%;
  left: 5%;
}

.modal--success {
  background-color: var(--primaire);
}

.modal--error {
  background-color: var(--primaire);
}


/**********************************/
/**********************************/
/*  2. HEADINGS  */


.heading-secondary {
  font-size: 2.25rem;
  text-transform: uppercase;
  font-weight: 700;
  /* background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--quaternaire)),
    to(var(--quaternaire))
  ); */
  /* background-image: linear-gradient(to right, var(--quaternaire), var(--quaternaire)); */
  /* -webkit-background-clip: text; */
  /* color: transparent; */
  letter-spacing: 0.1rem;
  line-height: 1.3;
  display: inline-block;
}

.heading-secondary--error {
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(var(--quinquenaire)),
      to(var(--quinquenaire)));
  background-image: linear-gradient(to right, var(--quinquenaire), var(--quinquenaire));
  font-size: 3.5rem;
}

.heading-primary,
.heading-tertiary {
  /* color: var(--secondaire); */
  text-transform: uppercase;
  font-weight: 300;
}

.heading-primary span,
.heading-tertiary span {
  padding: 1rem 1.5rem;
  line-height: 1;
  /* -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(var(--quaternaire)),
    to(var(--quaternaire))
  );
  background-image: linear-gradient(to bottom right, var(--quaternaire), var(--quaternaire)); */
}

.heading-primary {
  font-size: 5rem;
  text-align: center;
  /* width: 70%;
  margin: 0 auto; */
}

.heading-tertiary {
  font-size: 2.75rem;
  text-align: right;
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  width: 70%;
  z-index: 10;
}


/**********************************/
/**********************************/
/*  3. BUTTONS  */

/******** Switch button toggle dark / light *************/
#switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 32px;
  margin-right: 20px;
}

#switch input {
  opacity: 0;
  /* width: 0;
  height: 0; */
  width: 60px;
  height: 32px;
}

#switch .slide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #666;
  /* transition: .4s; */
}

#switch .slide:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  transition: left .4s, background-color .4s;
}

input:not(:checked)+.slide:before {
  left: 0;
  background-color: #f2f2f2;
  background-image: url('./../../public/img/sun.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

input:checked+.slide {
  background-color: var(--tertiaire);
}

input:checked+.slide:before {
  /* -webkit-transform: translateX(28px);
  -ms-transform: translateX(28px);
  transform: translateX(28px); */
  left: 28px;
  background-color: #555;
  background-image: url('./../../public/img/moon.svg');
  background-repeat: no-repeat;
  background-size: 40px 40px;
  background-position: center center;
}

#switch .slide.round {
  border-radius: 32px;
}

#switch .slide.round:before {
  border-radius: 50%;
}

/* input:focus+.slide {
  box-shadow: 0 0 1px var(--tertiaire);
}
*/

button {
  font-weight: 300;
  font-family: 'Quicksand', sans-serif;
  /* color: #000; */
}

button:disabled {
  cursor: default;
  background-color: var(--overlay);
  color: var(--secondaire);
}

.btn,
.btn:link,
.btn:visited {
  text-align: center;
  width: auto;
  max-width: 300px;
  margin: 0 auto;
  font-size: 1.6rem;
  padding: 1.4rem 3rem;
  border-radius: 4px;
  font-weight: 600;
  /* border-radius: 10rem;
  font-weight: 400; */
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /*Add later when we use this for the button in form*/
  border: none;
  cursor: pointer;
}

.btn:hover:not([disabled]) {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
}

.btn:active {
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

button:focus,
.btn:focus {
  outline: none;
  /* background-color: var(--quaternaire); */
  /* color: #333; */
}

.btn--white {
  background-color: var(--primaire);
  color: #000;
}

.btn--grey {
  background-color: var(--overlay);
  color: var(--secondaire);
}

.btn--white::after {
  background-color: var(--primaire);
}

.btn--blue {
  background-color: var(--tertiaire);
  color: #fff;
}

.btn--blue::after {
  background-color: var(--tertiaire);
}

.btn--orange {
  background-color: var(--quinquenaire);
  color: #333;
}

.btn--orange::after {
  background-color: var(--quinquenaire);
}

.btn--small {
  padding: 1.25rem 3rem !important;
  font-size: 1.4rem !important;
  line-height: 1.6rem !important;
}

.btn--small:nth-child(2) {
  margin-left: 1rem;
}

.btn-small,
.btn-small:link,
.btn-small:visited {
  background-color: var(--tertiaire);
  color: var(--secondaire);
  font-size: 1.4rem;
  padding: 1.25rem 3rem;
  border-radius: 10rem;
  text-transform: uppercase;
  position: relative;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  border: none;
}

.btn-small:hover {
  background-color: var(--quaternaire);
}

.btn.action--btn svg {
  width: 30px;
  height: 20px;
}

.btn.action--btn {
  padding: 0.8rem !important;
  border-radius: 5rem;
  width: 100%;
}

button.selected,
.btn.selected {
  background-color: var(--quaternaire);
}

/**********************************/
/**********************************/
/*  4. TABLE  */

main#packets .table-container {
  width: 100%;
}

.table-container {
  width: 95%;
  margin: 0 auto;
  padding: 5rem 0;
}

th {
  font-size: 1.5rem;
}

td {
  text-align: center;
  font-size: 1.2rem;
}

.tag {
  color: white;
  font-weight: 400;
  border-radius: 10px;
  padding: 2px 5px;
}

.tag-no {
  color: #000;
}

.tag-red {
  background-color: red;
}

.tag-orange {
  background-color: #f78425;
}

.tag-blue {
  background-color: #5c74ff;
}

.tag-green {
  background-color: #c5ec46;
  color: #000;
}

.tag-grey {
  background-color: #eee;
  color: #000;
}

.tag-light-green {
  background-color: lightgreen;
}

.tag-medium-green {
  background-color: mediumspringgreen;
}

.tag-dark-green {
  background-color: darkgreen;
}

/**********************************/
/**********************************/
/*  5. HEADER  */

#menu input {
  display: none;
}

header {
  background-color: #444444;
  /* padding: 0 15rem; */
  height: 8rem;
  position: sticky;
  top: 0;
  z-index: 500;
}

.header {
  height: 8rem;
  /* width: 1200px; */
  max-width: 90%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* .header__sticky {
  transition-property: background-color,box-shadow;
  transition-duration: .3s;
  transition-timing-function: ease-out;
} */

.header__logo img {
  height: 3.5rem;
}

.section-header {
  position: relative;
  /* height: 38vw;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--section-rotate)),
    0 100%
  );
  -webkit-clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--section-rotate)),
    0 100%
  ); */
}

/* .heading-box {
  position: absolute;
  bottom: 13vw;
  left: 50%;
  top: 35%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
} */

.heading-box__group {
  /* color: #f7f7f7; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.heading-box__group:last-child {
  margin-top: 3rem;
}

.heading-box__detail {
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15);
}

.heading-box__detail svg {
  margin-right: 0.8rem;
}

.heading-box__detail:not(:last-child) {
  margin-right: 4rem;
}

.heading-box__icon {
  height: 2rem;
  width: 2rem;
  fill: currentColor;
  -webkit-filter: drop-shadow(0 0.75rem 0.5rem rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 0.75rem 0.5rem rgba(0, 0, 0, 0.25));
}

.section-description {
  /* background-color: #fcfcfc; */
  /* margin-top: calc(0px - var(--section-rotate)); */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.section-description>.overview-box {
  padding: 0 2vw;
  padding-top: 2vw;
  margin: 2vw 0;
  /* padding-bottom: calc(1vw + var(--section-rotate)); */
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 45%;
}

.section-description>.spacer {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 10%;
  text-align: center;
}

.overview-box {
  background-color: var(--primaire);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.overview-box__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

.overview-box__group {
  display: flex;
  margin-bottom: 1rem;
  /* flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; */
}

.overview-box__detail {
  font-size: 1.4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
}

.left-col,
.right-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}

.left-col {
  padding-right: 1rem;
}

.overview-box__detail svg {
  margin-right: 1.25rem;
}

.overview-box__detail:last-child {
  margin-bottom: 2.25rem;
}

.overview-box__icon {
  height: 2.25rem;
  width: 2.25rem;
  fill: var(--tertiaire);
  margin-right: 1rem;
}

.overview-box__label {
  font-weight: 700;
  margin-right: 2.25rem;
  text-transform: uppercase;
  font-size: 1.4rem;
}

.overview-box__text {
  text-transform: capitalize;
}

.overview-box__img {
  border-radius: 50%;
  height: 3.5rem;
  margin-right: 1.25rem;
}

/* .start:after {
  content: '--->';
  width: 2rem;
}

.end:after {
  content: '>';
  width: 2rem;
} */

.section-pictures {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  clip-path: polygon(0 var(--section-rotate),
      100% 0,
      100% calc(100% - var(--section-rotate)),
      0 100%);
  -webkit-clip-path: polygon(0 var(--section-rotate),
      100% 0,
      100% calc(100% - var(--section-rotate)),
      0 100%);
  margin-top: calc(0px - var(--section-rotate));
  position: relative;
  z-index: 1000;
}

.picture-box__img {
  display: block;
  width: 100%;
  height: 110%;
  -o-object-fit: cover;
  object-fit: cover;
}

.picture-box__img--1 {
  padding-top: 15%;
}

.picture-box__img--2 {
  padding-bottom: 15%;
}

.picture-box__img--3 {
  padding-bottom: 27%;
}

.card-container {
  position: relative;
  width: 33%;
}


/***********************************/
/********** MAP **************/
/***********************************/

main.map .section-map {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /* height: 65rem; */
  /* height:auto; */
  width: 66%;
  /* margin-top: calc(0px - var(--section-rotate)); */
}

main.map #map {
  position: fixed;
  top: 8rem;
  bottom: 16.5rem;
  right: 0;
  width: 66%;
  height: auto;
}

.section-map {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /* width: 66%; */
  /* margin-top: calc(0px - var(--section-rotate)); */
}

main.map h2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#map {
  position: relative;
  top: 0;
  height: 30rem;
  /* top: 8rem;
  bottom: 0;
  right: 0; */
  width: 100%;
}

#map2 {
  position: fixed;
  top: 8rem;
  bottom: 0;
  right: 0;
  width: 66%;
}

.marker-green {
  background-image: url('../img/pin-green.png');
}

.marker-orange {
  background-image: url('../img/pin-orange.png');
}

.marker-blue {
  background-image: url('../img/pin-blue.png');
}

.marker {
  background-size: cover;
  width: 32px;
  height: 40px;
  cursor: pointer;
}

.infoBubble {
  font-family: 'Quicksand', sans-serif;
  width: 25rem;
}

.H_ib {
  top: -4.5rem;
}

.H_copyright {
  background-color: rgba(255, 255, 255, 0.3) !important;
  color: rgb(150, 150, 150) !important;
}

.mapboxgl-popup {
  max-width: 25rem;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Quicksand', sans-serif;
  padding: 1.5rem !important;
  font-size: 1.4rem;
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.15) !important;
}

.section-reviews {
  margin-top: calc(0px - var(--section-rotate));
  padding: calc(5rem + var(--section-rotate)) 0;
  position: relative;
  z-index: 1000;
  background: -webkit-gradient(linear,
      left top,
      right bottom,
      from(#7dd56f),
      to(#28b487));
  background: linear-gradient(to right bottom, #7dd56f, #28b487);
  clip-path: polygon(0 var(--section-rotate),
      100% 0,
      100% calc(100% - var(--section-rotate)),
      0 100%);
  -webkit-clip-path: polygon(0 var(--section-rotate),
      100% 0,
      100% calc(100% - var(--section-rotate)),
      0 100%);
}

.reviews {
  padding: 5rem 0;
  display: grid;
  grid-column-gap: 6rem;
  grid-auto-flow: column;
  overflow-x: scroll;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}

.reviews__card {
  width: 30rem;
  padding: 4rem;
  background-color: var(--primaire);
  border-radius: 3px;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  scroll-snap-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.reviews:before,
.reviews:after {
  content: '';
  width: 2rem;
}

.reviews__avatar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 2rem;
}

.reviews__avatar-img {
  height: 4.5rem;
  border-radius: 50%;
  margin-right: 1.5rem;
}

.reviews__user {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.reviews__text {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-style: italic;
  font-weight: 400;
}

.reviews__rating {
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.reviews__star {
  height: 2rem;
  width: 2rem;
  margin-right: 1px;
}

.reviews__star--active,
.favorite__heart--active {
  fill: var(--tertiaire);
  stroke: var(--tertiaire);
}

.reviews__star--inactive,
.favorite__heart--inactive {
  fill: #bbb;
}

.section-cta {
  margin-top: calc(0px - var(--section-rotate));
  padding: 3rem;
  padding-bottom: 11rem;
  padding-top: calc(15rem + var(--section-rotate));
  background-color: var(--primaire);
}

.cta {
  position: relative;
  max-width: 105rem;
  margin: 0 auto;
  overflow: hidden;
  background-color: var(--primaire);
  padding: 9rem 5rem 9rem 21rem;
  border-radius: 2rem;
  -webkit-box-shadow: 0 3rem 8rem 0.5rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 3rem 8rem 0.5rem rgba(0, 0, 0, 0.15);
}

.cta__img {
  height: 15rem;
  width: 15rem;
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 1rem 0.5rem 3rem rgba(0, 0, 0, 0.15);
  box-shadow: 1rem 0.5rem 3rem rgba(0, 0, 0, 0.15);
}

.cta__img--logo {
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: -webkit-gradient(linear,
      left top,
      right bottom,
      from(#7dd56f),
      to(#28b487));
  background: linear-gradient(to right bottom, #7dd56f, #28b487);
  z-index: 10;
  -webkit-transform: translate(-35%, -50%);
  transform: translate(-35%, -50%);
}

.cta__img--logo img {
  width: 100%;
}

.cta__img--1 {
  -webkit-transform: translate(-10%, -50%) scale(0.97);
  transform: translate(-10%, -50%) scale(0.97);
  z-index: 9;
}

.cta__img--2 {
  -webkit-transform: translate(15%, -50%) scale(0.94);
  transform: translate(15%, -50%) scale(0.94);
  z-index: 8;
}

.cta__content {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto;
  grid-gap: 0.7rem;
  grid-auto-flow: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cta__text {
  font-size: 1.9rem;
  font-weight: 400;
}

.user-view {
  background-color: var(--primaire);
  max-width: 120rem;
  /* margin: 0 auto; */
  min-height: 100vh;
  border-radius: 3px;
  overflow: hidden;
  -webkit-box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.07);
  box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.07);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.user-view__menu {
  /* -webkit-box-flex: 32rem;
  -ms-flex: 32rem 0 0px;
  flex: 32rem 0 0; */
  width: 30%;
  background-image: -webkit-gradient(linear,
      left top,
      right bottom,
      from(var(--quaternaire)),
      to(var(--quaternaire)));
  background-image: linear-gradient(to right bottom, var(--quaternaire), var(--quaternaire));
  padding: 4rem 0;
}

.user-view__content {
  /* -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; */
  width: 70%;
  padding: 7rem 0;
}

.user-view__form-container {
  /* max-width: 68rem; */
  margin: 0 auto;
  padding: 0 8rem;
}


/**********************************/
/**********************************/
/*  0. FOOTER  */

.footer {
  z-index: 2;
  /* margin-top: 8rem; */
  background-color: var(--primaire);
  padding: 4rem 4rem 3rem 4rem;
  font-size: 1.5rem;
  display: grid;
  min-height: 16rem;
  grid-template-columns: auto auto;
  grid-row-gap: 0.75rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer__logo {
  grid-row: 1 / 3;
  -ms-flex-item-align: center;
  align-self: center;
}

.footer__logo img {
  height: 3rem;
}

.footer__nav {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}

.footer__nav li {
  margin-left: 1.5rem;
}

/* .footer__nav a {
  color: #000;
  text-decoration: none !important;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.footer__nav a:hover,
.footer__nav a:active {
  color: var(--tertiaire);
} */

.footer__copyright {
  justify-self: end;
  color: var(--secondaire);
}

/**********************************/
/**********************************/
/*  0. NAVIGATION  */


/* .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
} */

#menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  /* -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; */
}

#menu nav {
  display: flex;
  align-items: center;
}

/* .nav--home {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 40%;
  flex: 0 1 40%;
}

.nav--user {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 40%;
  flex: 0 1 40%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
} */

.nav__el,
.nav__el:link,
.nav__el:visited {
  color: #f7f7f7;
  text-transform: uppercase;
  font-size: 1.6rem;
  text-decoration: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-weight: 400;
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
}

.nav__el:hover,
.nav__el:active {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  text-shadow: 0 0.7rem 1rem black;
}

.nav__el:not(:last-child) {
  margin-right: 2rem;
}

.nav__el:focus {
  outline: none;
}

.nav__el--cta {
  padding: 1rem 3rem;
  border-radius: 10rem;
  border: 1px solid currentColor !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.nav__el--cta:hover {
  background-color: var(--primaire);
  color: #000;
  text-shadow: none;
  border-color: var(--primaire);
}

.nav__el svg {
  fill: #fff;
  width: 3rem;
  height: 3rem;
}

.nav__el--logout span {
  display: none;
}

.nav__search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav__search-btn {
  background: none;
  border: none;
  margin-right: 0.8rem;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.nav__search-btn svg {
  height: 2rem;
  width: 2rem;
  fill: var(--primaire);
}

.nav__search-input {
  font-family: inherit;
  font-weight: inherit;
  text-transform: uppercase;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--primaire);
  padding-bottom: 3px;
  border-bottom: 1px solid var(--secondaire);
  width: 18rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.nav__search-input::-webkit-input-placeholder {
  color: var(--placeholder);
}

.nav__search-input:-ms-input-placeholder {
  color: var(--placeholder);
}

.nav__search-input::-ms-input-placeholder {
  color: var(--placeholder);
}

.nav__search-input::placeholder {
  color: var(--placeholder);
}

.nav__search-input:focus {
  outline: none;
  width: 25rem;
  border-bottom: 1px solid currentColor;
}

.nav__user-img {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.side-nav {
  list-style: none;
}

.side-nav li {
  margin: 1rem 0;
  border-left: 0 solid #333;
  /* border-left: 0 solid var(--secondaire); */
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.side-nav--active,
.side-nav li:hover {
  border-left: 4px solid #333 !important;
  /* border-left: 4px solid var(--secondaire) !important; */
}

.side-nav--active a {
  -webkit-transform: translateX(-3px);
  transform: translateX(-3px);
}

.side-nav a:link,
.side-nav a:visited {
  padding: 1rem 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--secondaire);
  /* color: var(--secondaire); */
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 400;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.side-nav a:hover,
.side-nav a:active {
  -webkit-transform: translateX(3px);
  transform: translateX(3px);
}

.side-nav svg {
  height: 1.9rem;
  width: 1.9rem;
  fill: #333;
  /* fill: #f7f7f7; */
  margin-right: 2rem;
}

.admin-nav {
  margin-top: 5.5rem;
}

.admin-nav__heading {
  margin: 0 5rem 1.5rem 4rem;
  padding-bottom: 3px;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: var(--primaire);
  border-bottom: 1px solid currentColor;
}

.card-container {
  max-width: 120rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 33%;
  /* grid-template-columns: repeat(3, 1fr);
  grid-gap: 7rem; */
  /* grid-template-columns: repeat(1, 1fr);
  grid-gap: 0.5rem; */
}

.card {
  border-radius: 10px;
  padding: 2rem;
  overflow: hidden;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
  background-color: var(--primaire);
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; */
}

.scanned {
  background-color: var(--quaternaire);
}

.card.inline {
  flex-direction: row;
  justify-content: space-between;
  gap: 10px 20px;
  min-width: 0;
}

.card__header {
  position: relative;
}

.inline .card__header,
.inline .card__footer {
  flex-basis: 25%;
  flex-grow: 0;
}

.inline .card__details {
  flex-basis: 50%;
  flex-grow: 0;
}

.card__picture {
  position: relative;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 83%, 0% 98%);
  clip-path: polygon(0 0, 100% 0%, 100% 83%, 0% 98%);
  height: 215px;
}

.card__picture-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear,
      left top,
      right bottom,
      from(var(--quaternaire)),
      to(var(--quaternaire)));
  background-image: linear-gradient(to right bottom, var(--quaternaire), var(--quaternaire));
  opacity: 0.7;
}

.card__picture-img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.card__details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1.75rem;
  grid-column-gap: 2rem;
  padding: 2.5rem 3rem;
}

.card__sub-heading {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
  grid-column: 1 / -1;
}

.card__text {
  grid-column: 1 / -1;
  font-size: 1.5rem;
  font-style: italic;
  margin-top: -1rem;
  margin-bottom: 0.75rem;
}

.card__line {
  grid-column: 1 / -1;
  font-size: 1.3rem;
  line-height: 0.4rem;
}

.card__data {
  font-size: 1.3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card__data svg {
  margin-right: 0.7rem;
}

.card__icon {
  margin: 0 0.5rem -0.5rem 0;
  height: 2rem;
  width: 2rem;
  fill: var(--tertiaire);
}

.card__icon__inactive {
  height: 2rem;
  width: 2rem;
  fill: #777;
}

.card__footer {
  background-color: var(--primaire);
  padding: 2.5rem 3rem;
  border-top: 1px solid var(--primaire);
  font-size: 1.4rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin-top: auto;
}

.card__footer-value {
  font-weight: 700;
}

.card__footer-text {
  color: var(--secondaire);
}

.card__ratings {
  grid-row: 2 / 3;
}

.card .btn-small,
.card .btn {
  grid-row: 1 / 3;
  justify-self: end;
  -ms-flex-item-align: center;
  align-self: center;
}

.error {
  position: absolute;
  top: 35%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 80rem;
  text-align: center;
}

.error__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.error__emoji {
  font-size: 3.75rem;
  margin-left: 1rem;
}

.error__msg {
  font-size: 2.5rem;
  font-weight: 700;
  max-width: 50rem;
  margin: 0 auto;
}

/**********************************/
/**********************************/
/*  0. FORMS  */

form.form {
  margin-top: 3rem;
  width: 100%;
}

.form {
  margin: 0 auto;
  width: 55rem;
  max-width: 100%;
  background-color: var(--primaire);
  -webkit-box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.06);
  box-shadow: 0 2.5rem 8rem 2rem rgba(0, 0, 0, 0.06);
  padding: 5rem 7rem;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

form.form h2:nth-of-type(2) {
  margin-top: 6rem;
}

.form__2col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: center;
}

.form__col1 {
  width: 50%;
  padding-right: 2rem;
}

.form__col2 {
  width: 50%;
  padding-left: 2rem;
}

.simulateur {
  background-color: #444;
  border-radius: 1rem;
  margin-left: 2rem;
  padding: 2rem;
  width: calc(50% - 2rem);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.simulateur strong,
.simulateur button strong {
  color: white;
}

.simulateur h3 {
  color: white;
  margin-top: 0;
}

.result,
.result~strong {
  font-size: 4rem;
}

.form__input {
  display: block;
  font-family: inherit;
  font-size: 1.5rem;
  color: inherit;
  padding: 1.25rem 1.75rem;
  border: none;
  width: 100%;
  background-color: var(--overlay);
  /* background-color: #f2f2f2; */
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* Pseudo element (a visible thing that isn't really in the DOM).
      Also needs -ms- */
}

.form__input.w33,
.form__label.w33 {
  margin-right: 1px;
  width: 33%;
  float: left;
}

.form__input:focus {
  outline: none;
  border-bottom: 3px solid #5c74ff;
}

.form__input:focus:invalid {
  border-bottom: 3px solid #f78425;
}

.form__input::-webkit-input-placeholder {
  color: var(--placeholder);
}

.form__group:not(:last-child) {
  margin-bottom: 1.5rem;
}

input[type=checkbox] {
  accent-color: var(--tertiaire);
}

input.input-error {
  border: 1px solid #f78425;
}

input.input-error+span.input-error {
  display: block !important;
  color: #f78425;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.checkbox>input {
  margin-top: 0.5rem;
  margin-right: 0.75rem;
}

.checkbox>label {
  font-size: 1.5rem;
}

#signup {
  padding-top: 0;
}

#signup .form--signup {
  margin-top: 0;
}

div#type {
  max-width: 600px;
  width: 80%;
  margin: 0 auto;
}

div#type input:checked+label,
div#type input:checked+label h4 {
  color: #eee;
}

div#type input:checked+label svg {
  fill: #eee;
}

div#type svg {
  width: 5rem;
  height: 5rem;
}

div#type>label:not(:nth-of-type(2)) svg {
  transform: scale(-1, 1);
  /* color: red; */
}

div#type h4 {
  text-align: center;
}

div#type span {
  font-size: 1.2rem;
}

#passwordCurrent,
#password,
#passwordConfirm {
  letter-spacing: 0.125rem;
  font-size: 1.5rem;
}

.eye {
  position: relative;
  top: -4rem;
  right: 0;
}

svg.on,
svg.off {
  position: absolute;
  right: 2rem;
  height: 3rem;
  width: 3rem;
  fill: var(--secondaire);
}

svg.off {
  display: none;
}

.form__input option {
  width: 100%;
}

.form__label {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
}

.form__photo-upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.6rem;
}

.form__user-photo {
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 50%;
  margin-right: 2rem;
}

.form__upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.form__upload:focus+label {
  outline: 3px solid var(--tertiaire);
  outline-offset: 3px;
}

.form__upload+label {
  color: var(--tertiaire);
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid var(--tertiaire);
  padding: 3px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

.form__upload+label:hover {
  background-color: var(--tertiaire);
  color: var(--secondaire);
  -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}

.form>a {
  font-size: 1.4rem;
  /* display: block;
  margin: 0 auto; */
}

.form a {
  text-decoration: underline;
}

.form__group {
  position: relative;
}

#geocoder .mapboxgl-ctrl-geocoder,
#geocoder .mapboxgl-ctrl-geocoder--input {
  width: 100%;
  max-width: 100%;
}

#geocoder1 {
  position: relative;
  z-index: 20;
}

.mapboxgl-ctrl-geocoder {
  width: 100%;
  max-width: 420px;
}

#geocoder2 {
  position: relative;
  z-index: 10;
}

input[type=radio]:not([name=defaultSize]):not([name=size]) {
  display: none;
}

input[type=radio][name=size],
input[type=radio][name=defaultSize] {
  margin-right: 2rem;
}

/* Remove arrows in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows in Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.unit {
  position: relative;
}

.unit::after {
  position: absolute;
  right: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  top: calc(50% - 1.25rem);
  content: attr(data-unit);
}

label.packetSize {
  display: inline-block;
  height: 3rem;
  width: 80%;
}

label.packetSize span {
  font-size: 1.5rem;
  font-weight: 600;
}

.radiobtn {
  display: flex;
  justify-content: flex-start;
  /* border: 0.95px #444 solid;
  border-radius: 5px; */
}

.radiobtn>label {
  cursor: pointer;
  margin: 0;
  padding: 1rem;
  text-align: center;
  transition: .25s ease-out;
  background-origin: padding-box;
  background-clip: padding-box;
  border: 1px #444 solid;
}

.form__label.inactive {
  pointer-events: none;
  color: var(--secondaire);
  background-color: var(--overlay);
}

/* .form__label.inactive>small,
.form__label.inactive>br {
  display: none;
} */

label[for='slot1'],
label[for='slot2'] {
  flex: 0 0 50%;
}

label[for='slot1'],
label[for='weight1'] {
  border-right: 0;
  border-radius: 4px 0 0 4px;
}

label[for='weight1'],
label[for='weight6'] {
  width: 16%;
}

label[for='weight2'],
label[for='weight3'],
label[for='weight4'],
label[for='weight5'] {
  border-right: 0;
  width: 16%;
}

label[for='slot2'],
label[for='weight6'] {
  /* border-left: 0; */
  border-radius: 0 4px 4px 0;
}

/* div.path:has(input[type="radio"]:checked), */
input[type=radio]:checked+label {
  background-color: var(--tertiaire);
  color: var(--primaire);
}

input[type=radio]:checked+label[for='slot2'] {
  background-color: var(--quinquenaire);
  color: var(--secondaire);
}

.qs-datepicker-container {
  position: relative;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  font-size: 1.5rem;
  font-family: 'Quicksand', sans-serif;
  color: var(--secondaire);
  background-color: var(--primaire);
  z-index: 1;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#formDashboard .qs-datepicker-container {
  position: absolute;
  top: 6rem !important;
}

#formChangePlan .qs-datepicker-container {
  position: absolute;
  top: 7rem !important;
}

.qs-controls {
  height: 4rem;
  background-color: var(--primaire);
}

.qs-square {
  height: 3rem;
}

.qs-num:hover {
  background-color: var(--quinquenaire);
  color: var(--secondaire);
}

.qs-num:active,
.qs-active,
.qs-range-start,
qs-range-end {
  background-color: var(--tertiaire);
  color: var(--primaire);
}

.slidercontainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.slider {
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: rgb(211, 211, 211);
  outline: none;
  transition: .2s;
}

.slider::-webkit-slider-thumb:hover,
.slider::-moz-range-thumb {
  background: var(--quinquenaire);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--tertiaire);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--tertiaire);
  cursor: pointer;
}

.bubble {
  display: inline-block;
  position: relative;
  width: 60px;
  color: var(--secondaire);
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: var(--tertiaire);
  padding: 5px 10px;
  margin-left: 8px;
  font-size: 1.3rem;
  font-weight: 600;
}

.bubble:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid var(--tertiaire);
  border-bottom: 7px solid transparent;
  content: "";
}

/****************************************************/
/**************** SEND PACKET PAGE ******************/
/****************************************************/

#send-packet {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

#send-packet>form {
  position: relative;
  width: 70%;
  margin: 0;
  padding: 3rem;
}

#send-packet section.section-map {
  width: 60%;
  position: absolute;
  top: 3rem;
  right: 3rem;
  padding-top: 0;
  z-index: 499;
}

#send-packet .send-packet-summary {
  margin: 0;
  padding: 3rem;
  width: 28%;
}

#send-packet .form__label {
  font-size: 1.4rem;
}

#send-packet .form__input {
  font-size: 1.3rem;
  padding: 0.75rem 1.25rem;
}

#send-packet .qs-datepicker-container .qs-controls {
  height: 2rem;
}

#send-packet .qs-datepicker-container .qs-controls,
#send-packet .qs-datepicker-container .qs-square {
  height: 2rem;
}

#send-packet .card-container {
  width: 35%;
  height: 25rem;
}

#step1>.flex-row>.flex-col,
#step2>.flex-row>.flex-col {
  width: 49%;
}

#send-packet .card-container .dropdown-content {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

#relsContainer1>div,
#relsContainer2>div {
  cursor: pointer;
  padding: 0.8rem;
  border-bottom: 1px solid #ccc;
  margin: 1.5rem 0 0 0;
  overflow: hidden;
}

#send-packet .card-container .selected {
  border: 1px solid var(--tertiaire);
}

#send-packet .sender {
  color: var(--tertiaire);
}

#send-packet .customer {
  color: var(--quaternaire);
}



/****************************************************/
/****************** PAYMENT FORM ********************/
/****************************************************/

.form--pay-packet .hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

#countdown {
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 2rem;
}

/* Buttons and links */
/* .form--pay-packet button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  min-height: 43px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.form--pay-packet button:hover {
  filter: contrast(115%);
}

.form--pay-packet button:disabled {
  opacity: 0.5;
  cursor: default;
} */


/******************************************/
/**************** SCAN PAGE ***************/
/******************************************/
#scanWindow {
  /* display: none; */
  display: block;
  width: 50%;
  margin: 0 auto;
}

#canvas {
  /* display: none; */
  display: block;
  width: 100%;
}


/******************************************/
/**************** DASHBOARD ***************/
/******************************************/

#formDashboard {
  width: 100%;
  justify-content: center;
}

#formDashboard>div {
  justify-content: flex-start;
}

#dashboard .card {
  padding: 1rem 2rem;
}

#dashboard .card h3 {
  margin-top: 1rem;
}

#dashboard .card h4 {
  margin-top: 0;
  font-size: 1rem;
}

.spacer-blank {
  height: 3rem;
}

.spacer-line {
  height: 5rem;
  border-bottom: 1px solid #bbb;
  width: 50%;
  margin: 0 auto;
}

.no-min-height {
  max-height: auto;
}

.min-height {
  min-height: 280px;
}

.min-height-small {
  min-height: 150px;
}

.cardw-19 {
  width: 19%;
}

.cardw-24 {
  width: 24%;
}

.cardw-32 {
  width: 32%;
}

.cardw-100 {
  width: 100%;
}

.cardw-32:not(:first-child) {
  margin-left: 2%;
}

/******************************************/
/************** PAGE PRICES ***************/
/******************************************/

/* #prices {
  width: auto;
}
 */
#prices .card {
  padding: 2rem 0.5rem;
}

#prices .card h3 {
  color: var(--tertiaire);
  font-size: 2.5rem;
}

#prices>p {
  text-align: center;
}

#prices li {
  font-size: 1.3rem;
  line-height: 3rem;
}

#prices h4 span {
  font-size: 2.2rem;
}

#prices h4 span.barre {
  font-size: 1.2rem;
  text-decoration: line-through;
  font-weight: 300;
}

#prices h4 span.small {
  font-size: 1rem;
  font-weight: 300;
}

#tab1 .card {
  width: 16%;
}

#tab2 .card {
  width: 19%;
}

#promo {
  max-width: 50rem;
  background-color: var(--quinquenaire);
  border-radius: 10px;
  padding: 1rem 2rem 2rem 2rem;
}

#promo,
#faq {
  margin: 0 auto;
  width: 60%;
}

#faq>div {
  border: 1px dotted var(--secondaire);
  border-radius: 10px;
  margin-bottom: 1rem;
}

#faq h3 {
  margin-top: 0;
  padding: 2rem 1rem;
  cursor: pointer;
}

#faq p {
  padding: 0 1rem;
  margin-top: 0;
}

#faq input[type=checkbox]:not(:checked)+p {
  display: none;
}

#faq input[type=checkbox]:checked+p {
  display: block;
}


/**********************************/
/**********************************/
/*  0. MEDIA QUERY -- Medium Laptop  */

@media only screen and (max-width: 84rem) {

  /* 1344px (1rem = 16px) */

  /**********************************/
  /**********************************/
  /*  0. MEDIA QUERY -- Medium Laptop  */
  .card__picture {
    height: 15vw;
  }

}

@media only screen and (max-width: 78rem) {

  /* 1248px (1rem = 16px) */

  #orizone,
  #quoi,
  #qui,
  #comment {
    padding-top: 8rem;
  }

  #main-vid {
    height: 30vw;
  }

  #img2 {
    display: none;
  }

  #img3 {
    display: none;
  }

  #img1 {
    width: 100%;
    top: 12vw;
    right: 0rem;
  }

  #img1.rotate {
    width: 100%;
    top: 12vw;
    right: 0rem;
  }

  .play-sign {
    top: calc(50% + 8vw - 50px);
    left: calc(50% - 50px);
  }

  /* #quoi>div,
  #avantages>div {
    align-items: flex-start;
  } */

}

/**********************************/
/**********************************/
/*  0. MEDIA QUERY -- Small Laptop  */

@media only screen and (max-width: 62.5rem) {

  /* 1000px (1rem = 16px) */
  body {
    font-size: 0.8rem;
    /* padding: 1rem; */
  }

  h1,
  h1>span,
  h1>strong {
    font-size: 3.5rem;
  }

  #main {
    flex-direction: column;
    padding-top: 0;
  }

  #orizone,
  #quoi,
  #qui,
  #comment {
    padding-top: 6rem;
  }

  #main-text {
    width: 100%;
    align-items: center;
  }

  #main-text li {
    justify-content: center;
  }

  #main-text a {
    margin: 0 auto;
  }

  #main-vid {
    width: 100%;
    height: 38vw;
  }

  #img1 {
    width: 60%;
    top: 6vw;
    right: 20%;
  }

  #img1.rotate {
    width: 60%;
    top: 6vw;
    right: 20%;
  }

  .play-sign {
    top: calc(50% + 3vw - 50px);
  }

  /* video#video {
    position: relative;
    margin: -36vw 0 0 0;
  } */

  #video-container {
    margin-top: 10rem;
    width: 100%;
    padding-bottom: 51.2%;
    position: relative;
  }

  #video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #quoi :nth-child(2) img,
  #avantages :nth-child(odd) img {
    margin-left: 5%;
    width: 30vw;
  }

  #quoi :nth-child(3) img,
  #avantages :nth-child(even) img {
    margin-right: 5%;
    width: 30vw;
  }

  .header {
    width: 100%;
    padding: 0.5rem;
    /* -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; */
  }

  .header__logo {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .nav--home {
    margin-bottom: 1.5rem;
  }

  .user-view__content {
    /* -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; */
    width: 80%;
    padding: 5rem 0;
  }

  .footer {
    padding: 0.5rem;
  }

  .alert {
    padding: 1.6rem 0rem;
  }

  .card__details {
    grid-template-columns: 1fr;
    grid-row-gap: 1.75rem;
    padding: 0.5rem 0.5rem;
  }

  /* #canvas {
    width: 100%;
  } */

  .filters {
    padding: 0;
  }

  .filters .row {
    margin: 0;
  }

  #send-packet {
    flex-direction: column;
    align-items: center;
  }

  #send-packet>form,
  #send-packet .send-packet-summary,
  #send-packet section.section-map {
    width: 100%;
    position: relative;
  }

  #send-packet section.section-map {
    width: 100%;
    position: relative;
    top: 0;
    right: 0;
    margin-bottom: 2rem;
  }

  #send-packet .card-container {
    width: 100%;
    flex-direction: column;
  }

  #send-packet .send-packet-summary {
    margin-top: 2rem;
  }

  #send-packet #step3 .radiobtn {
    flex-direction: column;
  }

  #send-packet #step3 .radiobtn>label {
    border: 1px #444 solid;
  }

  label[for='weight1'] {
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
  }

  label[for='weight1'],
  label[for='weight6'] {
    width: 100%;
  }

  label[for='weight2'],
  label[for='weight3'],
  label[for='weight4'],
  label[for='weight5'] {
    border-bottom: 0;
    width: 100%;
  }

  label[for='weight6'] {
    border-radius: 0 0 4px 4px;
  }

}

/**********************************/
/**********************************/
/*  0. MEDIA QUERY -- Tablet  */

@media only screen and (max-width: 50rem) {

  /* 800px (1rem = 16px) */
  body {
    padding: 0;
  }

  h2 {
    text-align: center;
  }

  .heading-secondary {
    display: block;
  }

  #main-vid {
    height: 52vw;
  }

  #img1 {
    width: 80%;
    top: 4vw;
    right: 10%;
  }

  #img1.rotate {
    width: 80%;
    top: 4vw;
    right: 10%;
  }

  .play-sign {
    top: calc(50% - 50px);
  }

  video#video {
    position: relative;
    margin: -50vw 0 -1vw 0;
  }

  #quoi :nth-child(2) {
    flex-direction: column;
  }

  #quoi :nth-child(3) {
    flex-direction: column-reverse;
  }

  #quoi :nth-child(2) img,
  #quoi :nth-child(3) img,
  #avantages :nth-child(odd) img,
  #avantages :nth-child(even) img {
    margin: 0 auto;
    width: auto;
  }

  #avantages>:nth-child(odd) {
    flex-direction: column;
  }

  #avantages>:nth-child(even) {
    flex-direction: column-reverse;
  }

  #qui>div {
    flex-direction: column;
  }

  #comment>div {
    flex-direction: column;
  }

  #comment>div div {
    width: 100%;
  }

  #qui>div a {
    width: 100%;
    margin-bottom: 5%;
  }

  #qui .card {
    height: auto;
  }

  #qui .card__picture {
    height: 100%;
  }

  #tab1,
  #tab2 {
    flex-direction: column;
    align-items: center;
  }

  #prices .card {
    width: 60%;
    margin-bottom: 2rem;
  }

  #promo,
  #faq {
    width: 100%;
  }

  #dashboard-user {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .footer {
    grid-template-columns: 1fr;
    grid-row-gap: 1.25rem;
    justify-items: center;
    min-height: 16rem;
  }

  .footer__logo {
    grid-row: 1;
  }

  .footer__copyright {
    justify-self: center;
  }

  #scanWindow {
    display: block;
  }

  #canvas {
    display: block;
  }

  .form {
    padding: 3rem 1rem;
  }

  .form__2col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  .form__col1 {
    width: 80%;
    padding-right: 0;
  }

  .form__col2 {
    width: 80%;
    padding-left: 0;
    margin-top: 3rem;
  }

  .simulateur {
    margin-left: 0;
    padding: 2rem;
    width: 80%;
  }

  form.form>.form__group {
    width: 80%;
    margin: 0 auto;
    /* margin-top: 3rem; */
  }

  form.form h2:nth-of-type(1) {
    margin-top: 1vw;
  }

  form.form h2:nth-of-type(2) {
    margin-top: 6rem;
  }

  div#type {
    max-width: 600px;
    width: 100%;
  }

  .user-view__content {
    /* -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; */
    width: 100%;
    padding: 3rem 0;
  }

  .user-view__form-container {
    padding: 0;
  }

  #formDashboard {
    flex-direction: column;
    align-items: center;
  }

  #formDashboard>div {
    justify-content: space-between;
    width: 100%;
  }

  .flex-row-card {
    flex-direction: column;
  }

  .min-height,
  .min-height-small {
    min-height: auto;
  }

  .cardw-19 {
    width: 100%;
  }

  .cardw-24 {
    width: 100%;
  }

  .cardw-32 {
    width: 100%;
  }

  .cardw-32:not(:first-child) {
    margin-left: 0;
  }

  .flex-row-card>div:not(:first-child) {
    margin-top: 2rem;
  }

  .flex-row-card>div {
    width: 100%;
  }

  .filters .row {
    flex-direction: column;
  }

  .filters .row>div {
    width: 80%;
    margin-bottom: 1rem;
  }

  .filters .row .pagination {
    line-height: 4rem;
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .filters .pagination a {
    flex-grow: 0.15;
    font-size: 1.5rem;
  }

  #packets .card {
    flex-direction: column;
  }

  #packets .card>div,
  #packets .card>p {
    width: 100%;
  }

  form.form-rel>div,
  form.form-shop>div {
    flex-direction: column;
  }

  form.form-rel>div>div,
  form.form-shop>div>div {
    width: 100%;
    padding: 0 !important;
  }

  form.form-rel>div>.w30>button,
  form.form-shop>div>.w30>button {
    margin: 0 auto !important;
    margin-bottom: 2rem !important;
  }

  form.form-rel>div>.w70>.right,
  form.form-shop>div>.w70>.right {
    text-align: center !important;
  }

  form.form-rel .flex-row-card>*,
  form.form-shop .flex-row-card>* {
    width: 100%;
  }

  form.form-rel #dateContainer>div {
    justify-content: space-between !important;
  }

  main.map .card-container {
    margin-left: 1rem;
    width: 55%;
    max-width: 100%;
  }

  main.map section-map {
    width: 45%;
  }

  main.map #map {
    width: 54%;
  }

}


/**********************************/
/**********************************/
/*  0. MEDIA QUERY -- Mobile  */

@media only screen and (max-width: 40rem) {

  /* 640px (1rem = 16px) */

  /* #splash {
    display: block;
  } */

  .alert {
    left: 0;
    width: 100%;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    padding: 3rem;
  }

  #main-text h5 {
    width: auto;
  }

  #main-vid {
    height: 60vw;
  }

  #img1 {
    width: 90%;
    top: 4vw;
    right: 5%;
  }

  #img1.rotate {
    width: 90%;
    top: 4vw;
    right: 5%;
  }

  .play-sign {
    top: calc(50% - 50px);
  }

  video#video {
    position: relative;
    margin: -57vw 0 4vw 0;
  }

  #orizone .card {
    flex-direction: column;
  }

  #orizone .card>div {
    width: auto;
  }

  #orizone .card>:nth-child(1) {
    width: 60%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  #quoi :nth-child(2) img,
  #quoi :nth-child(3) img,
  #avantages :nth-child(odd) img,
  #avantages :nth-child(even) img {
    width: 100%;
  }

  header {
    height: 100%;
    position: relative;
  }

  .header {
    /* position: relative; */
    width: 100%;
    max-width: 100%;
    height: 6rem;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .header__right {
    flex-direction: row-reverse;
  }

  #switch {
    margin-right: 0;
    margin-left: 20px;
  }

  .nav--home {
    margin-bottom: 0;
  }

  .nav__el:not(:last-child) {
    margin-right: 0;
    margin-bottom: 1.2rem;
  }

  .nav__search {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    margin-bottom: 1.2rem;
  }

  .header__logo {
    padding: 2rem;
  }

  main.map .section-map {
    width: 100%;
    height: 30rem;
    padding-top: 1rem;
  }

  main.map {
    flex-direction: column;
    height: 100%;
    padding: 0 1rem;
  }

  main.map .card-container {
    flex-direction: column;
    margin-left: 0;
    width: 100%;
  }

  main.map #map {
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 100%;
  }

  #map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  #map2 {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  .section-map {
    height: 30rem;
    /* margin-top: 6rem; */
    width: 100%;
  }

  .card-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
  }

  .card {
    width: 100%;
    padding: 2rem;
  }

  #prices .card {
    width: 80%;
  }

  div#type label {
    width: 32%;
    padding: 1rem;
  }

  div#type label span {
    font-size: 1.1rem;
  }

  #scanWindow {
    display: block;
    width: 100%;
    overflow: hidden;
  }

  #canvas {
    width: 100%;
    display: block;
  }

  .user-view {
    min-height: 0;
    flex-direction: column;
  }

  .user-view__menu {
    width: 100%;
    padding: 0;
  }

  .user-view__content {
    width: 100%;
    padding: 0;
  }

  /**********************************/
  /*  0-0. Burger Menu  */

  /* 
  body
  {
    margin: 0;
    padding: 0;
    
    background: #232323;
    color: #cdcdcd;
    font-family: "Avenir Next", "Avenir", sans-serif;
  } 
  */

  #menu {
    display: block;
    position: relative;
    top: 0px;
    left: 10px;

    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
  }

  #menu a {
    text-decoration: none;
    color: var(--secondaire);

    transition: color 0.3s ease;
  }

  #menu input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0;
    /* hide this */
    z-index: 2;
    /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  #menu>span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #fff;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      opacity 0.55s ease;
  }

  #menu>span:first-child {
    transform-origin: 0% 0%;
  }

  #menu>span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
  #menu>input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: var(--secondaire);
  }

  /*
  * But let's hide the middle one.
  */
  #menu>input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
  * Ohyeah and the last one should go the other direction
  */
  #menu>input:checked~span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  /*
  * Make this absolute positioned
  * at the top left of the screen
  */
  #menu .nav {
    position: absolute;
    width: 100vw;
    margin: -100px 0 0 -15px;
    padding: 15px;
    padding-top: 125px;

    background: var(--primaire);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: flex-start
  }

  #menu .nav__el {
    padding: 10px 0;
    font-size: 22px;
  }

  #menu .nav__el:hover,
  #menu .nav__el:active {
    text-shadow: 0 0.7rem 1rem grey;
  }

  #menu .nav__el svg {
    /* display: none; */
    fill: var(--secondaire);
  }

  #menu .nav__el--logout span {
    margin-right: 0.5rem;
    display: block;
  }

  /*
  * And let's slide it in from the left
  */
  #menu input:checked~.nav {
    transform: none;
  }

  #pwaInstall {
    display: block;
  }

  main {
    padding: 3rem 0;
    max-width: 95%;
    min-height: calc(100vh - 22rem);
  }

  .section-description {
    flex-direction: column;
  }

  .qs-datepicker-container {
    font-size: 2rem;
  }

  .qs-square {
    height: 4rem;
  }

  .form__col1,
  .form__col2,
  .simulateur,
  form.form>.form__group {
    width: 100%;
  }

  form.form--signup {
    padding-top: 0;
  }

  .filters .row>div {
    width: 100%;
  }

  #step1>.flex-row,
  #step2>.flex-row {
    flex-direction: column;
  }

  #step1>.flex-row>.flex-col,
  #step2>.flex-row>.flex-col {
    width: 100%;
  }

}

/**********************************/
/**********************************/
/*  0. MEDIA QUERY -- Standalone mode  */

@media all and (display-mode: standalone) {

  /* 480px (1rem = 16px) */

  #splash {
    display: block;
  }

  #pwaInstall {
    display: none;
  }

  main {
    /* min-height: calc(100vh - 22rem); */
    min-height: calc(var(--vh, 1vh) * 100 - 22rem);
  }
}